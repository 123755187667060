<template>
    <div class="train-manage">
        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>训练管理</el-breadcrumb-item>
                <el-breadcrumb-item>训练信息</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="train-txt">
            <span>训练信息</span>
            <el-button class="reset-train" @click="dialogVisible = true">重置训练</el-button>
        </div>
        <div class="train-container">
            <el-table :data="trainList" border style="width: 100%; margin-top: 20px" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#666', background: '#F5F5F5'}"
                      :cell-style="{fontSize: '14px',color: '#333'}">
                <el-table-column prop="sort" align="center" label="训练管理">
                    <template slot-scope="scope">
                        <span>第{{scope.row.sort}}次</span>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="kf_score" align="center" :label="`客户服务(${the_kfModulePercent}%)`" sortable>-->
<!--                    <template slot-scope="scope">-->
<!--                        <span v-if="scope.row.kf_score === null">0.00</span>-->
<!--                        <span v-else v-html="(scope.row.kf_score*the_kfModulePercent/100).toFixed(2)"></span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="operation_score" align="center" :label="`店铺营销(${operationPercent}%)`" sortable>-->
<!--                    <template slot-scope="scope">-->
<!--                        <span v-if="scope.row.operation_score === null">0.00</span>-->
<!--                        <span v-else v-html="(scope.row.operation_score*operationPercent/100).toFixed(2)"></span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column prop="store_settings_train_score" align="center" :label="`网店规划与注册(${storeRegisterPercent}%)`" sortable>
                    <template slot-scope="scope">
                        <span>{{scope.row.store_settings_train_score.toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="new_kefu_score" align="center" :label="`客服服务(${the_new_kfModulePercent}%)`" sortable>
                    <template slot-scope="scope">
                        <span>{{scope.row.new_kefu_score?(scope.row.new_kefu_score*the_new_kfModulePercent/100).toFixed(2):0.00}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="live_sale_score" align="center" :label="`直播销售(${liveModulePercent}%)`" sortable>
                    <template slot-scope="scope">
                        <span>{{scope.row.live_sale_score ? Number(scope.row.live_sale_score * liveModulePercent / 100).toFixed(2):0.00}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-link :underline="false" class="link-blue-light" @click="trainDetail(scope.row)">查看详情</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="operation-pagination" style="margin: 20px 0 0;"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="pagesCurrentChange">
            </el-pagination>
        </div>
        <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="608px">
            <div class="train-dialog-content">
                <span>1.重置训练将会清空当前训练数据，学生可以重新开始训练</span>
                <span>2.每一次训练成绩详情可在训练管理进行查看</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="resetTrain" class="btn-red">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import _ from "underscore";
    export default {
        name: "Index",
        data() {
            return {
                //训练列表
                trainList: [],
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                //提示弹窗
                dialogVisible: false,
                //运营模板id
                operationId: null,
                //店铺营销百分比
                operationPercent: '',
                //竞赛模板id
                tmpId: null,
                //客服模块百分比
                the_kfModulePercent: '',
                // 新客服服务模本百分比
                the_new_kfModulePercent:'',
                storeRegisterPercent: '',
                liveModulePercent: ''
            }
        },
        mounted() {
            this.getTrainList();
        },
        methods: {
            //获取训练列表
            getTrainList() {
                let param = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.getOneStuData, param, (res) => {
                    if (res.code === 200) {
                        this.trainList = res.data.data;
                        this.listPages.total = res.data.total;
                        this.operationId = res.data.data[0].operation_id;
                        //获取店铺营销百分比
                        if (this.operationId) {
                            this.$http.axiosGetBy(this.$api.showOperationList, {type: 'all', id: this.operationId,}, (res) => {
                                if (res.code === 200) {
                                    let operationItem = res.data[0];
                                    if (operationItem) {
                                        let selectItem = _.find(operationItem.extend, {op_module_type: 2});
                                        this.operationPercent = selectItem.op_module_percent;
                                    }
                                } else {
                                    this.$message.warning(res.msg);
                                }
                            }, (err) => {
                                console.log(err);
                            })
                        }

                        let param = {
                            com_id: Number(res.data.data[0].competition_id)
                        }
                        //获取竞赛模板id
                        this.$http.axiosGetBy(this.$api.getCom, param, (res) => {
                            if (res.code === 200) {
                                this.tmpId = res.data.tmp_id;
                                this.kfModulePercent();
                                this.newkfModulePercent();
                            } else {
                                this.$message.warning(res.msg);
                            }
                        }, (err) => {
                            console.log(err);
                        })
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取客服模块百分比
            kfModulePercent() {
              this.$http.axiosGetBy(this.$api.templateList, {id: this.tmpId}, (res) => {
                  if (res.code === 200) {
                      let competitionItem = _.find(res.data.expand, {tmpl_module_type: 7});
                      this.the_new_kfModulePercent = competitionItem.tmpl_module_percent;
                  } else {
                      this.$message.warning(res.msg);
                  }
              }, (err) => {
                  console.log(err);
              })
            },
            // 获取新客服服务模块百分比
            newkfModulePercent() {
              this.$http.axiosGetBy(this.$api.templateList, {id: this.tmpId}, (res) => {
                  if (res.code === 200) {
                      let competitionItem = _.find(res.data.expand, {tmpl_module_type: 3});
                      this.the_new_kfModulePercent = competitionItem.tmpl_module_percent;
                      let flag = res.data.expand.find((item) => {
                          if (item.tmpl_module_type == 9) {
                              return item
                          }
                      })
                      this.liveModulePercent = flag.tmpl_module_percent
                    let findRegister = res.data.expand.find(item => item.tmpl_module_type === 8)
                    if (findRegister) {
                      this.storeRegisterPercent = findRegister.tmpl_module_percent
                    }
                  } else {
                      this.$message.warning(res.msg);
                  }
              }, (err) => {
                  console.log(err);
              })
            },
            //重置训练
            resetTrain() {
                this.$http.axiosGet(this.$api.resetData, (res) => {
                    if (res.code === 200) {
                        this.dialogVisible = false;
                        if (this.trainList.length === 0) {
                            window.location.reload();
                        } else {
                            this.getTrainList();
                        }
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //查看详情
            trainDetail(row) {
                this.$router.push({
                    path: '/student/trainmanage/traindetail',
                    query: {
                        uid: row.user_id,//用户id
                        xlid: row.id,//训练id
                        cid: row.competition_id, // 比赛id
                        sort:row.sort, //次数
                        kf: this.the_kfModulePercent,//客服比例
                        yx: this.operationPercent,//营销比例
                        xlrzid: row.new_kefu_log_id,//客服训练日志id
                    }
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val;
                this.getTrainList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .train-manage {
        min-height: calc(100vh - 120px);
        background: #F7F9FB;
        .header{
            span{
                font-size: 14px;
            }
        }
        .train-txt{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #333333;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-size: 20px;
            font-weight: 500;
            line-height: 1;
            margin: 20px 0;
            .reset-train {
                border: 1px solid #FD4446;
                padding: 10px 11px;
                color: #FD4446;
                &:hover {
                    color: #fd4446d9;
                    background-color: #fff;
                    border: 1px solid #fd4446d9;
                }
            }
        }
        .train-container {
            background: #fff;
            padding: 20px;
            min-height: calc(100vh - 210px);
            /*height: calc(100vh - 189px);*/
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
        }
    }
    .train-dialog-content {
        display: flex;
        flex-direction: column;
        line-height: 2;
    }
</style>